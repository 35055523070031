<template>
  <div class="newMetals_wrap">
    <div class="main_box">
      <div class="issueRight_wrap">
        <!-- 基本信息 -->
        <div class="BaseForm_box">
          <!-- 基础信息表单 -->
          <el-form ref="form" :model="goosdBaseForm" label-width="80px">
            <el-form-item label="配件名称" prop="name">
              <el-input v-model="goosdBaseForm.name" size="small" style="width: 550px"
                placeholder="请输入配件名称" maxlength="20" show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="配件编号" prop="accessoriesNo">
              <el-input v-model="goosdBaseForm.accessoriesNo" size="small" style="width: 550px"
                placeholder="请输入配件编号" maxlength="10" show-word-limit
              ></el-input>
              <div class="b-info">编号：数字+字母的格式</div>
            </el-form-item>
            <el-form-item label="配件描述"  prop="description">
              <el-input  v-model="goosdBaseForm.description" size="small" style="width: 550px"
                placeholder="请输入商品描述" maxlength="60" show-word-limit
              ></el-input>
              <div class="b-info">详情页下面展示，建议60字以内</div>
            </el-form-item>
            <el-form-item label="配件主图" style="margin-top: -10px">
              <!-- <el-upload
                action="#"
                :file-list="goosdBaseForm.goodsImages"
                list-type="picture-card"
                :auto-upload="false"
                :limit="15"
                multiple
                :on-remove="handleRemove"
                :on-success="uploadSuccess"
                ><i class="el-icon-plus"></i>
                <div slot="tip" class="b-info">
                  建议尺寸:300px*200px,比例为2:3;支持格式:jpg、png;
                </div>
              </el-upload> -->
               <el-button @click="upload">上传主图</el-button>
            </el-form-item>
            <!-- <el-form-item label="颜色分类" prop="colorClass">
              <div class="color_box" style="margin-top: 10px">
                <div class="header">
                  <div class="lable1">颜色分类</div>
                  <div class="lable1">颜色展示图</div>
                </div>
                <div
                  class="item"
                  v-for="(item, index) in goosdBaseForm.colorClass" :key="index">
                  <div class="colorName">
                    <el-select
                      size="small"
                      v-model="item.colorNmae"
                      filterable
                      placeholder="请选择">
                      <el-option
                        v-for="item in colorOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="colorImage">
                    <div>选择图片</div>
                    <img
                      v-if="item.colorImage != ''"
                      :src="item.colorImage"
                      alt=""/>
                    <div v-if="item.colorImage != ''" class="del">
                      X
                    </div>
                  </div>
                  <div class="delItem" @click="delItem(index)">刪除</div>
                </div>
            </div>
            <div class="addBtn">
                <div class="addColor" @click="addColor">+</div>
            </div>
            </el-form-item> -->
            <el-form-item label="配件类型" prop="catalogId">
              <!-- <el-select
                v-model="goosdBaseForm.catalogId"
                size="small"
                placeholder="请选择类型"
                style="width: 300px">
                <el-option
                  v-for="item in metalsClassOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
              <el-cascader
                v-model="goosdBaseForm.catalogId"
                :options="metalsClassOptions"
                :props="{ checkStrictly: true }"
                clearable></el-cascader>
            </el-form-item>

            <el-form-item label="配件价格" prop="price">
              <div class="u-f-ac">
                <el-input
                  size="small"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  v-model="goosdBaseForm.price"
                  style="width: 100px"
                ></el-input>
                <div class="unit">元</div>
              </div>
            </el-form-item>
            <el-form-item label="配件库存" prop="inventory">
              <div class="u-f-ac">
                <el-input  style="width: 100px"
                  v-model="goosdBaseForm.inventory"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  size="small"
                ></el-input>
                <div class="unit">
                  <el-input size="small" v-model="goosdBaseForm.units" 
                    style="width: 100px" placeholder="请输入单位" ></el-input>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
        <el-button type="info" @click="cancel">取消</el-button>
        <el-button type="primary"  @click="submitForm">{{id ? '修改配件' : '创建配件'}}</el-button>
      </div>
      </div>
    </div>
    <!-- 选择视频图片弹窗 -->
    <uploadData ref="uploadData" @selectImages="selectImages" :graphics="graphics" :uploadType="uploadType"></uploadData>
  </div>
</template>
<script>
import uploadData from '@/components/uploadData'
export default {
    components:{
        uploadData
    },
    data() {
        return {
        goosdBaseForm: {
            name: "",
            accessoriesNo: "",
            description: "",
            imageUrl: null,
            // colorClass: [{ colorNmae: "", colorImage: "" }],
            colorIds: null,
            catalogId: null,
            inventory: "",
            price: "",
            units: ''
        },
        metalsClassOptions: [
            {
            value: "选项1",
            label: "把手",
            },
            {
            value: "选项2",
            label: "执手",
            },
            {
            value: "选项3",
            label: "锁盒",
            },
        ],
        colorOptions: [],
        graphics: 100,  //上传图片数量
        uploadType: '',  //上传图片类型
        id: null,
      };
    },
    mounted(){
      this.id = this.$route.query.id;
      if(this.id){
        this.getAccessoriesInfo();
      }
      this.getListCatalog();
    },
    methods: {
      // 获取配件类型
      getListCatalog(){
        this.$API.getCatalogList().then( res => {
            if(res.code === 0){
                let resultSecond;
                var result = res.data.map(o=>{
                    if(o.childrenNode){
                        resultSecond =  o.childrenNode.map(c=>{
                            return{
                                value: c.data.id, 
                                label: c.data.classifyName,
                                createTime: c.data.createTime
                            }
                        })
                    }
                    return{
                        value: o.data.id, 
                        label: o.data.classifyName,
                        createTime: o.data.createTime,
                        children: resultSecond
                    }
                });
                this.metalsClassOptions = result;
            }
        })
      },
      // 获取配件信息
      getAccessoriesInfo(){
        let pamars = {
          id: this.id
        }
        this.$API.getAccessoriesInfo(pamars).then( res => {
            if(res.code === 0){
              this.goosdBaseForm = res.data;
            }
          })
      },
      // 提交保存 --- 添加-修改
      submitForm(){
        if(this.id){
          // 修改
          this.goosdBaseForm.id = this.id
          this.$API.updateAccessories(this.goosdBaseForm).then( res => {
            if(res.code === 0){
                this.$message.success("修改配件成功")
                this.$router.push('mountingsList');
            }
          })
        }else{
          // 添加
          let parentId = this.goosdBaseForm.catalogId;
          let id = parentId[parentId.length-1];
          this.goosdBaseForm.catalogId = id
          this.$API.addAccessories(this.goosdBaseForm).then( res => {
            if(res.code === 0){
                this.$message.success("添加配件成功");
                this.goosdBaseForm = {}
            }
          })
        }
      },
      //选中图片
      selectImages(value,type){
        let imgList = [];
        imgList.push(value[0].id)
        this.goosdBaseForm.imageUrl = imgList
      },
      upload(){
        this.graphics = 1;
        this.uploadType = 'main'
        this.$refs.uploadData.getDate()
      },
        handleRemove(){},
        addColor() {
            let colorClass = {
                name: "",
                colorImage: "",
            };
            this.goosdBaseForm.colorClass.push(colorClass);
        },
        delItem(index) {
            this.goosdBaseForm.colorClass.splice(index, 1);
        },
        cancel() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss">
.newMetals_wrap {
  height: 100%;
  margin: 20px;
  .main_box {
    .issueRight_wrap {
        height: 100%;
        background-color: #fff;
        padding: 20px;
        .BaseForm_box {
            .el-form-item{
                margin-bottom: 10px;
            }
            .ivBorder {
                width: 150px;
                height: 100px;
                font-size: 45px;
                color: rgb(189, 188, 188);
                border: 1px dashed rgb(189, 188, 188);
                border-radius: 6px;
            }
            .b-info {
                font-size: 12px;
                color: rgb(150, 151, 153);
                line-height: 25px;
            }
            .u-f-ac{
                display: flex;
                align-items: center;
            }
            .unit {
                font-size: 12px;
                padding-left: 5px;
            }
            .color_box {
                width: 560px;
                .header {
                    font-size: 14px;
                    background-color: #eee;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    display: flex;
                    flex-flow: wrap;
                    align-items: center;
                    .lable1 {
                    width: 230px;
                    text-align: center;
                    }
                }
                .item {
                    font-size: 12px;
                    background-color: rgb(235, 249, 250);
                    height: 50px;
                    display: flex;
                    align-items: center;
                    .colorName {
                        width: 200px;
                        padding: 10px;
                    }
                    .colorImage {
                        color: rgb(90, 88, 211);
                        width: 280px;
                        text-align: center;
                    img {
                        margin-left: 10px;
                        width: 60px;
                        height: 40px;
                    }
                    .del {
                        width: 20px;
                        height: 20px;
                        margin-left: 5px;
                        background-color: #dee0e4;
                        border-radius: 50%;
                    }
                    }
                    .delItem {
                        width: 80px;
                        color: red;
                    }
                }
            }
            .addBtn{
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 550px;
                .addColor {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 30px;
                    color: blueviolet;
                    border-radius: 50%;
                    background-color: antiquewhite;
                    
                }
            }
        }
        .btn {
            padding: 10px 0;
            .el-button{
              width: 100px;
            }
        }
    }
    .btn {
      margin-top: 10px;
    }
  }
}
</style>